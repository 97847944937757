import { createContext, ReactNode, useContext } from 'react';
import { Channel } from '../site-api-types';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

const ChannelContext = createContext<Channel | undefined>(undefined);

export const ChannelProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: any;
}) => (
  <ChannelContext.Provider value={value}>{children}</ChannelContext.Provider>
);

export function useChannel() {
  const context = useContext(ChannelContext);
  if (context === undefined) {
    throw new Error('useChannel must be used within a Channel Provider');
  }
  return context;
}

export const ChannelConsumer = ChannelContext.Consumer;

import { Alerts, AlertsProvider } from '@resal-frontend/ui/lib/alerts';
import { polyfill } from '@resal-frontend/utils';
import { UIStateStore } from '@stores';
import 'keen-slider/keen-slider.min.css';
import App, { AppProps } from 'next/app';
import React, { useState } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import GoogleTagManager from '../components/TagManager';
import { ChannelProvider } from '../utils/channel-context';
import { getSlug } from '../utils/get-slug';
import { init } from '../utils/sentry';
import './styles.css';

init();

const hexToRgb = (hex: string, r: number, g: number, b: number) => {
  return `${parseInt(hex.slice(1, 3), 16) ?? r}, ${
    parseInt(hex.slice(3, 5), 16) ?? g
  }, ${parseInt(hex.slice(5, 7), 16) ?? b}`;
};

const hexToRGB = (hex: string): [number, number, number] => {
  let r = '0';
  let g = '0';
  let b = '0';
  // hex is #ffeeff
  if (hex.length === 4) {
    // short hex eg: #fff
    r = '0x' + hex[1] + hex[1];
    g = '0x' + hex[2] + hex[2];
    b = '0x' + hex[3] + hex[3];
  } else if (hex.length == 7) {
    r = '0x' + hex[1] + hex[2];
    g = '0x' + hex[3] + hex[4];
    b = '0x' + hex[5] + hex[6];
  }
  return [+r / 255, +g / 255, +b / 255];
};

const getLightness = (hexOrRGB?: string) => {
  if (hexOrRGB == null) return 100;
  let components: [number, number, number] = [0, 0, 0];
  if (hexOrRGB[0] === '#') {
    components = hexToRGB(hexOrRGB);
  } else if (hexOrRGB.indexOf('rgb') === 0) {
    const values = hexOrRGB
      .slice(hexOrRGB.indexOf('('), hexOrRGB.indexOf(')'))
      .split(',')
      .map((comp) => parseInt(comp) / 255);
    components = [values[0], values[1], values[2]];
  }
  return (Math.min(...components) + Math.max(...components)) / 2;
};

function CustomApp({
  Component,
  pageProps,
  locale,
  messages,
  channel,
}: AppProps & {
  locale: 'ar' | 'en';
  messages: Record<string, string>;
  channel: any;
}) {
  const [queryClientRef] = useState(new QueryClient());
  return (
    <QueryClientProvider client={queryClientRef}>
      <ChannelProvider value={channel}>
        <IntlProvider
          locale={locale}
          defaultLocale="ar"
          messages={messages}
          formats={{
            number: {
              currency: {
                style: 'currency',
                currency: 'SAR',
              },
            },
          }}
        >
          <Hydrate state={pageProps.dehydratedState}>
            <GoogleTagManager />
            <AlertsProvider>
              <Component {...pageProps} />
              <FormattedMessage id="global.ok" defaultMessage="OK">
                {(OKMessage) => (
                  <Alerts
                    showBackdrop={() => UIStateStore.getState().mode === 'idle'}
                    OKMessage={OKMessage as unknown as string}
                  />
                )}
              </FormattedMessage>
            </AlertsProvider>
          </Hydrate>
        </IntlProvider>
      </ChannelProvider>
      <style global jsx>{`
        body {
          --color-text-primary: ${hexToRgb(channel.colors.main, 14, 67, 241)};
          --color-text-primary-inverted: ${getLightness(
            channel.colors.main ?? 'rgb(14, 67, 241)'
          ) < 0.5
            ? '0, 0, 0'
            : '255, 255, 255'};
          --color-fill-primary: ${hexToRgb(channel.colors.main, 14, 67, 241)};
          --color-fill-secondary: ${hexToRgb(
            channel.colors.second,
            148,
            224,
            209
          )};
          --color-fill-tertiary: ${hexToRgb(
            channel.colors.third,
            148,
            224,
            209
          )};
          --color-fill-tertiary-inverted: ${getLightness(
            channel.colors.third ?? 'rgb(14, 67, 241)'
          ) < 0.5
            ? '255, 255, 255'
            : '0, 0, 0'};
          --color-text-header: ${hexToRgb('#456AF5', 14, 67, 241)};
        }
      `}</style>
    </QueryClientProvider>
  );
}

function getMessages(
  locales: string | string[] = ['ar']
  // eslint-disable-next-line @typescript-eslint/ban-types
): [string, Promise<{ default: {} }>] {
  if (!Array.isArray(locales)) {
    locales = [locales];
  }
  let langBundle;
  let locale;
  for (let i = 0; i < locales.length && !locale; i++) {
    locale = locales[i];
    switch (locale) {
      case 'ar':
        langBundle = import('../compiled-lang/ar.json');
        break;
      case 'en':
        langBundle = import('../compiled-lang/en.json');
        break;
      default:
        break;
    }
  }
  if (!langBundle) {
    return ['ar', import('../compiled-lang/ar.json')];
  }
  return [locale || 'ar', langBundle];
}

const getInitialProps: typeof App.getInitialProps = async (appContext) => {
  const {
    router: { locale, defaultLocale, pathname },
    ctx,
  } = appContext;
  if (pathname === '/health-check') {
    return App.getInitialProps(appContext);
  }
  // TODO: get subdomain
  const [supportedLocale, messagePromise] = getMessages([
    locale || defaultLocale || 'ar',
  ]);
  const slug = getSlug(ctx.req?.headers?.host);
  const [, messages, appProps] = await Promise.all([
    polyfill(supportedLocale),
    messagePromise,
    App.getInitialProps(appContext),
  ]);
  return {
    ...(appProps as AppProps),
    locale: supportedLocale,
    messages: messages.default,
    channel: {
      logo: { url: '/channels.svg', width: 150, height: 80, extension: 'svg' },
      colors: { main: '#FD9A28', second: '#0F43F1', third: '#FFECD8' },
      name: 'Resal Channels',
    },
  };
};
CustomApp.getInitialProps = getInitialProps;

export default CustomApp;

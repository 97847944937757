import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';

export interface AlertType {
  message: React.ReactNode;
  title?: string;
  buttons?: Array<{ id: string; title: string; fn?: (id: string) => void }>;
}

const AlertStateContext = createContext<AlertType[] | undefined>(undefined);
const AlertUpdateContext = createContext<
  Dispatch<SetStateAction<AlertType[]>> | undefined
>(undefined);

export const AlertsProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<AlertType[]>([]);
  return (
    <AlertStateContext.Provider value={state}>
      <AlertUpdateContext.Provider value={setState}>
        {children}
      </AlertUpdateContext.Provider>
    </AlertStateContext.Provider>
  );
};

export const useAlertState = () => {
  const alerts = useContext(AlertStateContext);
  if (alerts == undefined) {
    throw new Error('missing Alerts provider');
  }
  return alerts;
};

export const useAlerts = () => {
  const setAlerts = useContext(AlertUpdateContext);
  if (setAlerts == null) {
    throw new Error('missing Alerts Provider');
  }
  const addAlert = useCallback(
    (alert: AlertType) => setAlerts((curr) => [...curr, alert]),
    [setAlerts]
  );
  // @ts-ignore
  typeof window !== 'undefined' && (window.addAlert = addAlert);
  return {
    addAlert,
    removeAlert: useCallback(
      (alert: AlertType) =>
        setAlerts((curr) => curr.filter((curAlert) => curAlert !== alert)),
      [setAlerts]
    ),
  };
};
export { Alerts } from './alerts';

export const SIFT_ID = process.env.NEXT_PUBLIC_SIFT_ID;

export const siftPageview = () => {
  const w: typeof window & { _sift?: any[] } = window;
  if (w._sift == null) {
    w._sift = [];
  }
  w._sift.push(['_trackPageview']);
};

let knownuid: string | undefined = undefined;
export const setSiftUser = (uid: string) => {
  // make sure to set it once
  if (knownuid === uid) return;
  knownuid = uid;
  const w: typeof window & { _sift?: any[] } = window;
  if (w._sift == null) {
    w._sift = [];
  }
  w._sift.push(['_setUserId', uid]);
};

export const setSessionId = (sid: string) => {
  // make sure to set it once
  const w: typeof window & { _sift?: any[] } = window;
  if (w._sift == null) {
    w._sift = [];
  }
  w._sift.push(['_setSessionId', sid]);
};

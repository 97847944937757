import create from 'zustand';
import { combine } from 'zustand/middleware';

export type UIState =
  | 'idle'
  | 'login'
  | 'register'
  | 'otp'
  | 'forgot'
  | 'card'
  | 'cardpreview'
  | 'howto'
  | 'rating';

const getDefault = (): { mode: UIState } => ({ mode: 'idle' });

export const UIStateStore = create(
  combine(getDefault(), (set) => ({
    setUIMode(mode: UIState) {
      set({ mode });
    },
  }))
);

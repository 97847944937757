import { memo, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { useRouter } from 'next/router';
import { gtm } from '@resal-frontend/utils';
import * as sift from '../../utils/sift';

export interface GoogleTagManagerProps {}

const GoogleTagManager = ({}: GoogleTagManagerProps) => {
  const router = useRouter();

  useEffect(() => {
    const cb = (url: string) => {
      gtm.pageview(url);
      sift.siftPageview();
    };
    router.events.on('routeChangeComplete', cb);
    return () => {
      router.events.off('routeChangeComplete', cb);
    };
  }, [router.events]);

  useEffect(() => {
    sift.setSessionId(nanoid());
  }, []);

  return null;
};

export default memo(GoogleTagManager);

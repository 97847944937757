/* eslint-disable @typescript-eslint/no-explicit-any */
import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlLocale } from '@formatjs/intl-locale/should-polyfill';

/**
 * Dynamically polyfill Intl API & its locale data
 * @param locale locale to polyfill
 */
export async function polyfill(locale = '') {
  const dataPolyfills = [];
  // Polyfill Intl.getCanonicalLocales if necessary
  if (shouldPolyfillGetCanonicalLocales()) {
    await import(
      /* webpackChunkName: "intl-getcanonicallocales" */ '@formatjs/intl-getcanonicallocales/polyfill'
    );
  }
  // Polyfill Intl.Locale if necessary
  if (shouldPolyfillIntlLocale()) {
    await import(
      /* webpackChunkName: "intl-locale" */ '@formatjs/intl-locale/polyfill'
    );
  }

  // Polyfill Intl.PluralRules if necessary
  if (shouldPolyfillPluralRules()) {
    await import(
      /* webpackChunkName: "intl-pluralrules" */ '@formatjs/intl-pluralrules/polyfill'
    );
  }

  if ((Intl.PluralRules as any).polyfilled) {
    const lang = locale.split('-')[0];
    switch (lang) {
      case 'ar':
        dataPolyfills.push(
          import(
            /* webpackChunkName: "intl-pluralrules" */ '@formatjs/intl-pluralrules/locale-data/ar'
          )
        );
        break;
      default:
        dataPolyfills.push(
          import(
            /* webpackChunkName: "intl-pluralrules" */ '@formatjs/intl-pluralrules/locale-data/en'
          )
        );
        break;
    }
  }

  // Polyfill Intl.NumberFormat if necessary
  if (shouldPolyfillNumberFormat()) {
    await import(
      /* webpackChunkName: "intl-numberformat" */ '@formatjs/intl-numberformat/polyfill'
    );
  }

  if ((Intl.NumberFormat as any).polyfilled) {
    switch (locale) {
      case 'ar':
        dataPolyfills.push(
          import(
            /* webpackChunkName: "intl-numberformat" */ '@formatjs/intl-numberformat/locale-data/ar'
          )
        );
        break;
      default:
        dataPolyfills.push(
          import(
            /* webpackChunkName: "intl-numberformat" */ '@formatjs/intl-numberformat/locale-data/en'
          )
        );
        break;
    }
  }

  // Polyfill Intl.DateTimeFormat if necessary
  if (shouldPolyfillDateTimeFormat()) {
    await import(
      /* webpackChunkName: "intl-datetimeformat" */ '@formatjs/intl-datetimeformat/polyfill'
    );
  }

  if ((Intl.DateTimeFormat as any).polyfilled) {
    dataPolyfills.push(import('@formatjs/intl-datetimeformat/add-all-tz'));
    switch (locale) {
      case 'ar':
        dataPolyfills.push(
          import(
            /* webpackChunkName: "intl-datetimeformat" */ '@formatjs/intl-datetimeformat/locale-data/ar'
          )
        );
        break;
      default:
        dataPolyfills.push(
          import(
            /* webpackChunkName: "intl-datetimeformat" */ '@formatjs/intl-datetimeformat/locale-data/en'
          )
        );
        break;
    }
  }

  // Polyfill Intl.RelativeTimeFormat if necessary
  if (shouldPolyfillRelativeTimeFormat()) {
    await import(
      /* webpackChunkName: "intl-relativetimeformat" */ '@formatjs/intl-relativetimeformat/polyfill'
    );
  }

  if ((Intl as any).RelativeTimeFormat.polyfilled) {
    switch (locale) {
      case 'ar':
        dataPolyfills.push(
          import(
            /* webpackChunkName: "intl-relativetimeformat" */ '@formatjs/intl-relativetimeformat/locale-data/ar'
          )
        );
        break;
      default:
        dataPolyfills.push(
          import(
            /* webpackChunkName: "intl-relativetimeformat" */ '@formatjs/intl-relativetimeformat/locale-data/en'
          )
        );
        break;
    }
  }

  await Promise.all(dataPolyfills);
}

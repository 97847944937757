import create from 'zustand';
import { combine } from 'zustand/middleware';
import Cookies from 'js-cookie';
import { User } from '@httpclient';

const storageKeys = {
  accessToken: 'channels_token',
  user: 'channels_user',
  accessTokenExpireDate: 'channels_token_expire',
  hasWaitingOrders: 'channels_has_waiting_orders',
};

const cookieKeys = {
  AUTHORIZATION: 'Authorization',
};

const getDefault = ():
  | {
      accessToken: string;
      user: User;
      accessTokenExpireDate: Date;
      hasWaitingOrders: boolean;
    }
  | {
      accessToken: '';
      user: null;
      accessTokenExpireDate: Date;
      hasWaitingOrders: boolean;
    } => {
  if (typeof window !== 'undefined') {
    try {
      return {
        accessToken: localStorage.getItem(storageKeys.accessToken) || '',
        user: JSON.parse(localStorage.getItem(storageKeys.user) || 'null'),
        hasWaitingOrders: JSON.parse(
          sessionStorage.getItem(storageKeys.hasWaitingOrders) || 'false'
        ),
        accessTokenExpireDate: new Date(
          JSON.parse(
            localStorage.getItem(storageKeys.accessTokenExpireDate) || '0'
          ) || 0
        ),
      };
    } catch {
      return {
        accessToken: '',
        user: null,
        hasWaitingOrders: false,
        accessTokenExpireDate: new Date(0),
      };
    }
  }
  return {
    accessToken: '',
    user: null,
    hasWaitingOrders: false,
    accessTokenExpireDate: new Date(0),
  };
};

const getExpireDate = (token: string) => {
  let rval = new Date(0);
  const { 1: encodedPayload, length } = token.split('.');
  if (length !== 3) return rval;
  try {
    const payload = JSON.parse(atob(encodedPayload));
    return typeof payload.exp === 'number'
      ? new Date(payload.exp * 1000)
      : rval;
  } catch (e) {
    return rval;
  }
};

export const useTokenStore = create(
  combine(getDefault(), (set, get) => ({
    setTokens(tokens: { accessToken: string }) {
      try {
        localStorage.setItem(storageKeys.accessToken, tokens.accessToken);
        Cookies.set(cookieKeys.AUTHORIZATION, tokens.accessToken);
        const accessTokenExpireDate = getExpireDate(tokens.accessToken);
        localStorage.setItem(
          storageKeys.accessTokenExpireDate,
          `${accessTokenExpireDate.getTime()}`
        );
        set({
          ...tokens,
          accessTokenExpireDate,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('setting tokens failed', e);
      }
    },
    setUser(user: User) {
      try {
        localStorage.setItem(storageKeys.user, JSON.stringify(user));
        set({ user });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('setting tokens failed', e);
      }
    },
    clearTokens() {
      try {
        localStorage.removeItem(storageKeys.accessToken);
        localStorage.removeItem(storageKeys.user);
        localStorage.removeItem(storageKeys.accessTokenExpireDate);
        sessionStorage.removeItem(storageKeys.hasWaitingOrders);
        Cookies.remove(cookieKeys.AUTHORIZATION);
        set({
          accessToken: '',
          user: null,
          accessTokenExpireDate: new Date(0),
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('setting tokens failed', e);
      }
    },
    loggedIn: () =>
      get().accessToken !== '' && get().accessTokenExpireDate > new Date(),
    setHasWaitingOrders: (hasWaitingOrders: boolean) => {
      try {
        sessionStorage.setItem(
          storageKeys.hasWaitingOrders,
          JSON.stringify(hasWaitingOrders)
        );
        set({ hasWaitingOrders });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('setting hasWaitingOrders failed', e);
      }
    },
  }))
);

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

export const pageview = (url: string) => {
  const w: typeof window & { dataLayer?: any[] } = window;
  if (w.dataLayer == null) {
    w.dataLayer = [];
  }
  w.dataLayer.push({
    event: 'pageview',
    page: url,
  });
};

interface Product {
  name_ar: string;
  name_en: string;
  id: string | number;
  price: string | number;
  category: string;
  quantity: string | number;
}

export const beginCheckout = (
  products: Product[],
  step: number,
  option: string
) => {
  const w: typeof window & { dataLayer?: any[] } = window;
  if (w.dataLayer == null) {
    w.dataLayer = [];
  }
  w.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      checkout: {
        actionField: { step, option },
        products,
      },
    },
  });
};

export const ecommercePurchase = (event: {
  identifier: string;
  total: number;
  vat: number;
  products: Product[];
}) => {
  const w: typeof window & { dataLayer?: any[] } = window;
  if (w.dataLayer == null) {
    w.dataLayer = [];
  }
  w.dataLayer.push({
    event: 'ecommerce_purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: event.identifier, // Transaction ID. Required for purchases and refunds.
          affiliation: 'Online Web Store',
          revenue: event.total, // Total transaction value (incl. tax and shipping)
          tax: event.vat,
        },
        products: event.products,
      },
    },
  });
};

import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useAlerts, useAlertState, AlertType } from './index';
import { classNames } from '@resal-frontend/utils';

interface AlertProps {
  delay?: number;
  showBackdrop: () => boolean;
  OKMessage: string;
}
export const Alerts: React.FC<AlertProps> = ({
  delay,
  showBackdrop,
  OKMessage,
}) => {
  const alerts = useAlertState();
  const { removeAlert } = useAlerts();
  const [curAlert, setCurAlert] = useState<AlertType | undefined>();
  const [alertVisible, setAlertVisible] = useState(false);
  const completeButtonRef = useRef<HTMLButtonElement | null>(null);
  useEffect(() => {
    if (alerts.length > 0 && curAlert == null) {
      setCurAlert(alerts[0]);
      setAlertVisible(true);
    }
  }, [alerts]);
  return (
    <Transition
      appear
      show={alertVisible}
      as={Fragment}
      afterLeave={() => {
        console.log('after leave');
        if (curAlert != null) {
          removeAlert(curAlert);
        }
        setCurAlert(undefined);
      }}
    >
      <Dialog
        as="div"
        initialFocus={completeButtonRef}
        className="fixed inset-0 z-40 overflow-y-auto"
        onClose={() => {
          setAlertVisible(false);
        }}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 sc"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={classNames(
                'fixed inset-0',
                showBackdrop()
                  ? 'bg-gray-500 bg-opacity-75 transition-opacity'
                  : ''
              )}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-110"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-110"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900 text-center"
              >
                {curAlert?.title}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500 text-start">
                  {curAlert?.message}
                </p>
              </div>

              <div className="mt-4 flex justify-end">
                {(curAlert?.buttons ?? [{ id: '00', title: OKMessage }]).map(
                  (btn) => (
                    <button
                      key={btn.id}
                      type="button"
                      className="inline-flex justify-center mx-2 px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      ref={completeButtonRef}
                      onMouseDown={(evt) => {
                        evt.stopPropagation();
                        evt.preventDefault();
                      }}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        evt.preventDefault();
                        setAlertVisible(false);
                        if (btn.fn != null) {
                          btn.fn(btn.id);
                        }
                      }}
                    >
                      {btn.title}
                    </button>
                  )
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
